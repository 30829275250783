<template>
  <v-app>
    <v-app-bar app dark color="primary" flat>
      <v-toolbar-title>Circuito App</v-toolbar-title>

      <v-spacer></v-spacer>
    </v-app-bar>

    <v-bottom-navigation
      app
      v-model="value"
      background-color="primary"
      dark
      shift
      grow
    >
      <div v-for="(item, i) in items" :key="i"></div>

      <v-btn icon v-for="(item, i) in items" :key="i" @click="()=>$router.push(item.path)">
        <span>{{item.title}}</span>

        <v-icon>{{item.icon}}</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import admin_menu from "@/test/menu_admin.json";
import admin_user from "@/test/menu_user.json";

export default {
  name: "Frame",
  data: function () {
    return {
      // General
      value: 0,
      drawer: true,
      items: [],

      //@click="()=>$router.push('/login')"
      mini: false,
      // User
      user: null,
      user_loading: true,
      user_dialog: false,
      // Data Access
      accesses_loading: true,
      accesses: [],
    };
  },
  mounted() {
    this.getMenu();
  },
  methods: {
    getMenu(){
      let native_user = this.$store.getters.getUser;

      this.items = native_user.role === 'admin' ? admin_menu : admin_user;
    }
  },
  computed: {},
};
</script>